import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from "../guard/auth.guard";

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)},
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),canActivate: [AuthGuard]},
  { path: 'produtos', loadChildren: () => import('./pages/produtos/produtos.module').then(m => m.ProdutosPageModule),canActivate: [AuthGuard]  },
  { path: 'pedidos', loadChildren: () => import('./pages/pedidos/pedidos.module').then(m => m.PedidosPageModule),canActivate: [AuthGuard]  },
  { path: 'carros', loadChildren: () => import('./pages/carros/carros.module').then(m => m.CarrosPageModule),canActivate: [AuthGuard]  },
  { path: 'relatorios', loadChildren: () => import('./pages/relatorios/relatorios.module').then(m => m.RelatoriosPageModule),canActivate: [AuthGuard]  },
  { path: 'clientes', loadChildren: () => import('./pages/clientes/clientes.module').then(m => m.ClientesPageModule),canActivate: [AuthGuard]  },
  { path: 'novo-cliente', loadChildren: () => import('./pages/novo-cliente/novo-cliente.module').then(m => m.NovoClientePageModule),canActivate: [AuthGuard]  },
  { path: 'details-cliente/:key', loadChildren: () => import('./pages/details-cliente/details-cliente.module').then(m => m.DetailsClientePageModule),canActivate: [AuthGuard]  },
  { path: 'edit-cliente/:key', loadChildren: () => import('./pages/edit-cliente/edit-cliente.module').then(m => m.EditClientePageModule),canActivate: [AuthGuard]  },
  { path: 'vendedores', loadChildren: () => import('./pages/vendedores/vendedores.module').then(m => m.VendedoresPageModule),canActivate: [AuthGuard]  },
  { path: 'novo-vendedor', loadChildren: () => import('./pages/novo-vendedor/novo-vendedor.module').then(m => m.NovoVendedorPageModule),canActivate: [AuthGuard]  },
  { path: 'details-vendedor/:key', loadChildren: () => import('./pages/details-vendedor/details-vendedor.module').then(m => m.DetailsVendedorPageModule),canActivate: [AuthGuard]  },
  { path: 'edit-vendedor/:key', loadChildren: () => import('./pages/edit-vendedor/edit-vendedor.module').then(m => m.EditVendedorPageModule),canActivate: [AuthGuard]  },
  { path: 'fornecedores', loadChildren: () => import('./pages/fornecedores/fornecedores.module').then(m => m.FornecedoresPageModule),canActivate: [AuthGuard]  },
  { path: 'novo-fornecedor', loadChildren: () => import('./pages/novo-fornecedor/novo-fornecedor.module').then(m => m.NovoFornecedorPageModule),canActivate: [AuthGuard]  },
  { path: 'details-fornecedor/:key', loadChildren: () => import('./pages/details-fornecedor/details-fornecedor.module').then(m => m.DetailsFornecedorPageModule),canActivate: [AuthGuard]  },
  { path: 'edit-fornecedor/:key', loadChildren: () => import('./pages/edit-fornecedor/edit-fornecedor.module').then(m => m.EditFornecedorPageModule),canActivate: [AuthGuard]  },
  { path: 'novo-produto', loadChildren: () => import('./pages/novo-produto/novo-produto.module').then(m => m.NovoProdutoPageModule),canActivate: [AuthGuard]  },
  { path: 'details-produto/:key', loadChildren: () => import('./pages/details-produto/details-produto.module').then(m => m.DetailsProdutoPageModule),canActivate: [AuthGuard]  },
  { path: 'edit-produto/:key', loadChildren: () => import('./pages/edit-produto/edit-produto.module').then(m => m.EditProdutoPageModule),canActivate: [AuthGuard]  },
  { path: 'cestas', loadChildren: () => import('./pages/cestas/cestas.module').then(m => m.CestasPageModule),canActivate: [AuthGuard]  },
  { path: 'nova-cesta', loadChildren: () => import('./pages/nova-cesta/nova-cesta.module').then(m => m.NovaCestaPageModule) ,canActivate: [AuthGuard] },
  { path: 'details-cesta/:key', loadChildren: () => import('./pages/details-cesta/details-cesta.module').then(m => m.DetailsCestaPageModule),canActivate: [AuthGuard]  },
  { path: 'edit-cesta/:key', loadChildren: () => import('./pages/edit-cesta/edit-cesta.module').then(m => m.EditCestaPageModule),canActivate: [AuthGuard]  },
  { path: 'contas-receber', loadChildren: () => import('./pages/contas-receber/contas-receber.module').then(m => m.ContasReceberPageModule),canActivate: [AuthGuard]  },
  { path: 'contas-receber/:key', loadChildren: () => import('./pages/contas-receber/contas-receber.module').then(m => m.ContasReceberPageModule),canActivate: [AuthGuard]  },
  { path: 'nova-conta-receber', loadChildren: () => import('./pages/nova-conta-receber/nova-conta-receber.module').then(m => m.NovaContaReceberPageModule),canActivate: [AuthGuard]  },
  { path: 'details-conta-receber/:key', loadChildren: () => import('./pages/details-conta-receber/details-conta-receber.module').then(m => m.DetailsContaReceberPageModule),canActivate: [AuthGuard]  },
  { path: 'edit-conta-receber/:key', loadChildren: () => import('./pages/edit-conta-receber/edit-conta-receber.module').then(m => m.EditContaReceberPageModule),canActivate: [AuthGuard]  },
  { path: 'contas-pagar', loadChildren: () => import('./pages/contas-pagar/contas-pagar.module').then(m => m.ContasPagarPageModule),canActivate: [AuthGuard]  },
  { path: 'nova-conta-pagar', loadChildren: () => import('./pages/nova-conta-pagar/nova-conta-pagar.module').then(m => m.NovaContaPagarPageModule),canActivate: [AuthGuard]  },
  { path: 'details-conta-pagar/:key', loadChildren: () => import('./pages/details-conta-pagar/details-conta-pagar.module').then(m => m.DetailsContaPagarPageModule),canActivate: [AuthGuard]  },
  { path: 'edit-conta-pagar/:key', loadChildren: () => import('./pages/edit-conta-pagar/edit-conta-pagar.module').then(m => m.EditContaPagarPageModule),canActivate: [AuthGuard]  },
  { path: 'caixa', loadChildren: () => import('./pages/caixa/caixa.module').then(m => m.CaixaPageModule),canActivate: [AuthGuard]  },
  { path: 'edit-faturamento/:key', loadChildren: () => import('./pages/edit-faturamento/edit-faturamento.module').then(m => m.EditFaturamentoPageModule),canActivate: [AuthGuard]  },
  { path: 'logs', loadChildren: () => import('./pages/logs/logs.module').then(m => m.LogsPageModule),canActivate: [AuthGuard]  },
  { path: 'usuarios', loadChildren: () => import('./pages/usuarios/usuarios.module').then(m => m.UsuariosPageModule),canActivate: [AuthGuard]  },
  { path: 'trocas', loadChildren: () => import('./pages/trocas/trocas.module').then(m => m.TrocasPageModule),canActivate: [AuthGuard]  },
  { path: 'venda-interna', loadChildren: () => import('./pages/venda-interna/venda-interna.module').then(m => m.VendaInternaPageModule),canActivate: [AuthGuard]  },
  { path: 'venda-interna-cestas', loadChildren: () => import('./pages/venda-interna-cestas/venda-interna-cestas.module').then(m => m.VendaInternaCestasPageModule),canActivate: [AuthGuard]  },
  { path: 'logs-estoque', loadChildren: () => import('./pages/logs-estoque/logs-estoque.module').then(m => m.LogsEstoquePageModule),canActivate: [AuthGuard]  },
  { path: 'logs-estoque-carros', loadChildren: () => import('./pages/logs-estoque-carros/logs-estoque-carros.module').then(m => m.LogsEstoqueCarrosPageModule),canActivate: [AuthGuard]  },
  { path: 'agenda', loadChildren: () => import('./pages/agenda/agenda.module').then(m => m.AgendaPageModule),canActivate: [AuthGuard]  },
  { path: 'mobilite-passivos', loadChildren: () => import('./pages/mobilite-passivos/mobilite-passivos.module').then(m => m.MobilitePassivosPageModule),canActivate: [AuthGuard]  },
  { path: 'mobilite-rota', loadChildren: () => import('./pages/mobilite-rota/mobilite-rota.module').then(m => m.MobiliteRotaPageModule),canActivate: [AuthGuard]  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }