import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { DataBaseService } from './provider.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.scss'],

})
export class AppComponent implements OnInit{
  nomeUsuario:any = "";
  idUser:any = "";
  usuarioSistema:any = "";
  contasVencidas:any = "";
  usuario:any = [];
  regioesExcluir:any = [];
  juros:number;
  meses:number;
  mesesCobranca:number;
  ativado:boolean;
  regioesExclude:any = [];
  carencia:any;
  regiaoDestino:any;

  constructor(public authService:AuthService, public http:HttpClient, public db:DataBaseService) {
    this.nomeUsuario = localStorage.getItem('nomeUsuario');
    this.idUser = localStorage.getItem('idUser');
    http.get(db.API_URL_USUARIOS+'/'+this.idUser).subscribe((data:any) => {
      this.usuarioSistema = data;
    }) 
   }
  
   ngOnInit(){
    var OneSignal = window['OneSignal'] || [];
    OneSignal.push(function() {
      OneSignal.init({
        appId: "93da4b1a-21d3-4c4d-b28a-fb08eac1b33a",
        notifyButton: {
          enable: true,
          },
        });
      });
      if(this.nomeUsuario == "Administrador"){
        this.verificarCheques();
      }
  setInterval(() => {
    this.http.get(this.db.API_URL_USUARIOS+'/'+this.idUser).subscribe((data:any) => {
      this.usuario = data;
      if(this.usuario.situacao == "BLOQUEADO"){
        alert("USUÁRIO BLOQUEADO! POR FAVOR ENTRAR EM CONTATO COM O ESCRITÓRIO!")
        this.authService.logOut()
      }else{
       return
      }
  })}, 300000); 
    let dadosCodigo;
    this.http.get(this.db.API_URL_CODIGOS+'/5f31d4a0239e5f35802ed6ff').subscribe((dataCod:any) => {
      dadosCodigo = dataCod;
      this.regioesExcluir = dadosCodigo.regioesExcluir;
      this.juros = dadosCodigo.juros;
      this.meses = dadosCodigo.meses;
      this.mesesCobranca = dadosCodigo.mesesCobranca;
      this.ativado = dadosCodigo.ativado;
      this.carencia = dadosCodigo.carencia;
      this.regiaoDestino = dadosCodigo.regiaoDestino;
      if(this.ativado){
        let counter = 1;
        this.regioesExcluir.forEach(function(item){
          this.regioesExclude.push(
            {'regiaoCliente':item}
          );
          if(counter == this.regioesExcluir.length){
            this.enviarEscritorio();
          }else{
            counter++
          }
        }.bind(this))
      }
  })
}

enviarEscritorio(){
    let dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let dataAgora = new Date(Date.now())
    let vencidoHa = new Date();
    vencidoHa.setDate(dataAgora.getDate() - this.meses)
    let dataVencimento = vencidoHa.toISOString().split('T')[0];
    let carencia = new Date();
    carencia.setDate(dataAgora.getDate()-this.carencia);
    let dataCarencia = carencia.toISOString().split('T')[0];
  this.http.get(this.db.API_URL_CONTAS_RECEBER+'?filtro={"vencimento":{"$lte":"'+dataVencimento+'"},"$nor":'+JSON.stringify(this.regioesExclude)+'}&sort={"idCliente":1}').subscribe((data:any) => {
    this.contasVencidas = data;
    let contas = [];
    let control = 1;
    this.contasVencidas.forEach(function(contaVencidaItem){
        if(contas.length > 0){
         let indexDevedor = contas.map(function(conta){
            return conta.idCliente
          }).indexOf(contaVencidaItem.idCliente);
            if(indexDevedor >= 0){
              contas.splice(indexDevedor,1)
              contas.push(contaVencidaItem)
            }else{
              contas.push(contaVencidaItem)
            }
        }else{
          contas.push(contaVencidaItem)
        }
        if(control == this.contasVencidas.length){
          let faturas = [];
          contas.forEach(function(item){
          this.http.get(this.db.API_URL_FATURAMENTO+'?filtro={"idCliente":"'+item.idCliente+'","dataFaturamento":{"$gte":"'+dataCarencia+'", "$lte":"'+dataHoje+'"}}').subscribe((dadosFaturas:any) => {
          faturas = dadosFaturas;
          if(faturas.length > 0){
          }else{
            this.http.patch(this.db.API_URL_CLIENTES+'/'+item.idCliente,{
              regiaoCliente:this.regiaoDestino
            }).subscribe(res => {
              console.log(res);
              this.http.post(this.db.API_URL_HISTORICOS, {
                "cliente" : item.cliente, 
                "data" : dataHoje, 
                "hora" : hora, 
                "motivo" : "Mudança Endereço/Região", 
                "usuario" : "Sistema Automático", 
                "historico" : "Conta Vencida há mais de "+(this.meses/30)+" meses, região alterada de: "+item.regiaoCliente+" para: "+this.regiaoDestino, 
                "codigoCliente" : item.codigoCliente
              }).subscribe(res => {
                console.log(res);
                let contaVencida = [];
                this.http.get(this.db.API_URL_CONTAS_RECEBER+'?filtro={"idCliente":"'+item.idCliente+'","$nor":'+JSON.stringify(this.regioesExclude)+'}').subscribe((dataReceber:any) => {
                  contaVencida = dataReceber;
                  contaVencida.forEach(function(contaItem){            
                    this.http.patch(this.db.API_URL_CONTAS_RECEBER+'/'+contaItem._id,{
                      regiaoCliente : this.regiaoDestino,
                      valor: Number(((contaItem.valor * this.juros) + contaItem.valor).toFixed(2)),
                      dataInclusao: dataHoje 
                    }).subscribe(res => {
                      console.log(res);
                      this.http.post(this.db.API_URL_HISTORICOS,{
                        "cliente" : contaItem.cliente, 
                        "data" : dataHoje, 
                        "hora" : hora, 
                        "motivo" : "Cobrança", 
                        "usuario" : "Sistema Automático", 
                        "historico" : "Titulo: "+contaItem.titulo+" acrescido de "+(this.juros*100)+"% DE JUROS - Valor Anterior: R$"+contaItem.valor+", Valor com Juros: R$ "+Number(((contaItem.valor * this.juros) + contaItem.valor).toFixed(2)), 
                        "codigoCliente" : contaItem.codigoCliente
                      }).subscribe(res => {
                        console.log(res);
                        this.http.post(this.db.API_URL_LOGS, {
                          data: dataHoje,
                          hora: hora,
                          usuario: "Sistema Automático",
                          cliente: contaItem.cliente,
                          pagina: "SISTEMA",
                          codigoCliente : contaItem.codigoCliente,
                          descricao: "Titulo "+contaItem.titulo+" - Vencida há mais de "+(this.meses/30)+" meses, região da conta alterada de: "+contaItem.regiaoCliente+" para: "+this.regiaoDestino
                        }).subscribe(res => console.log(res), err => (console.log(err)))
                      }, err => console.log(err))
                    }, err => console.log(err))             
                  }.bind(this))
                })
              }, err => console.log(err))
          },err => console.log(err))
        }
      })
    }.bind(this))
  }else{
    control++
  }
}.bind(this))
  }) 
}

  verificarCheques(){
    let dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.http.get(this.db.API_URL_CHEQUES+'?filtro={"prazo":{"$gte":"2020-01-01","$lte":"'+dataHoje+'"}}').subscribe((data:any[]) => {
    let cheques = data;
    let qtde = cheques.length;
    if(qtde > 0){
      alert("Você possui "+qtde+" Cheques com Prazo até hoje!")
    }
  });
  }

}






