import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule,FaIconLibrary} from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrintModule } from 'ngx-print';
import { AuthService } from './auth.service';
import { AuthGuard } from 'src/guard/auth.guard';
import { DataBaseService } from './provider.service';
import { CpfCnpjModule } from 'ng2-cpf-cnpj';
import { NgHttpLoaderModule } from 'ng-http-loader'; 
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AppComponent,],
  entryComponents: [],
  imports: [
    LoadingBarRouterModule,
    NgHttpLoaderModule.forRoot(),
    CpfCnpjModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NgbModule,
    NgxPrintModule
    ],
  providers: [
    AuthGuard,
    StatusBar,
    SplashScreen,
    AuthService,
    DataBaseService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
  
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
   }
}
