import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()

export class AuthService {

constructor(public http:HttpClient, public route:Router) {}

login(user:any){
    this.http.get('https://api-prosp.herokuapp.com/usuarios?filtro={"email":"'+user.email+'"}').toPromise()
    .then(res => {
        if(res == ''){
          alert("USUARIO NÃO ENCONTRADO!")
        }else{
          if(res[0].senha == user.password){
            if(res[0].situacao!= "BLOQUEADO"){
              let storage = Math.random().toString(36).slice(-8);
              localStorage.setItem('tokenProsperity', JSON.stringify(storage));
              localStorage.setItem('nomeUsuario', res[0].nome);
              localStorage.setItem('idUser', res[0]._id);
              this.isLoggedIn();
              this.route.navigate(['home']);
              location.reload();
              }else{
                  alert("VENDEDOR BLOQUEADO! POR FAVOR COMPARECER AO ESCRITÓRIO!");
                  return;
              }
          } else{
            alert("SENHA INCORRETA!")
            return;
          }}
        },
        err => {
        if(err.status < 200){
          alert("Por Favor Verifique Sua Conexão com a Internet")
        }
      }
    ).catch(err => console.log(err))
}

  logOut() {
    localStorage.clear();
    this.isLoggedIn();
    this.route.navigate(['/login']);
  }

  isLoggedIn(): boolean {
    var token: String = this.getToken();
    return token && token.length > 0;
}

getToken(): String {
  var token = localStorage.getItem('tokenProsperity');
  return token ? token : "";
}

}