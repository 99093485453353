import { Injectable } from '@angular/core';

@Injectable()
export class DataBaseService {
   urlDataBase:string = "https://api-prosp.herokuapp.com"
   API_URL_CLIENTES = this.urlDataBase+'/clientes';
   API_URL_REGIOES = this.urlDataBase+'/regioes';
   API_URL_CFOP = this.urlDataBase+'/cfop';
   API_URL_NCM = this.urlDataBase+'/ncm';
   API_URL_PRODUTOS = this.urlDataBase+'/produtos';
   API_URL_HISTORICOS = this.urlDataBase+'/historicos';
   API_URL_FORNECEDORES = this.urlDataBase+'/fornecedores'; 
   API_URL_CONTAS = this.urlDataBase+'/contas';
   API_URL_VENDEDORES = this.urlDataBase+'/vendedores';
   API_URL_PEDIDOS = this.urlDataBase+'/pedidos';
   API_URL_CESTAS = this.urlDataBase+'/cestas';
   API_URL_CONTAS_PAGAR = this.urlDataBase+'/contas-pagar';
   API_URL_CONTAS_RECEBER = this.urlDataBase+'/contas-receber';
   API_URL_FATURAMENTO = this.urlDataBase+'/faturamento';
   API_URL_CODIGOS = this.urlDataBase+'/codigos';
   API_URL_LOGS = this.urlDataBase+'/logs';
   API_URL_USUARIOS = this.urlDataBase+'/usuarios';
   API_URL_TROCAS = this.urlDataBase+'/trocas';
   API_URL_VENDA_INTERNA = this.urlDataBase+'/venda-interna';
   API_URL_VENDA_INTERNA_CESTAS = this.urlDataBase+'/venda-interna-cestas';
   API_URL_ESTOQUE = this.urlDataBase+'/estoque';
   API_URL_CARROS = this.urlDataBase+'/carros';
   API_URL_ESTOQUE_CARROS = this.urlDataBase+'/estoque-carros';
   API_URL_AGENDA = this.urlDataBase+'/agenda';
   API_URL_CHEQUES = this.urlDataBase+'/cheques';
}